import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26a1d0f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row no-gutters"
}
const _hoisted_2 = { class: "col-auto" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "card-block px-2 restaurant-info" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.restaurant)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.restaurant.LogoUrl,
            class: "img-fluid",
            width: "70",
            alt: ""
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, "Restaurant Id: " + _toDisplayString(_ctx.restaurantId), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.restaurant.Name), 1),
            (_ctx.loadedRestaurantInfo(_ctx.restaurant))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.restaurant.Address.City) + ", " + _toDisplayString(_ctx.restaurant.Address.Postcode), 1))
              : _createCommentVNode("", true),
            (!_ctx.loadedRestaurantInfo(_ctx.restaurant))
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, "Restaurant not found"))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}