
import { inject, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import RestaurantDetails from '@/app/features/restaurant-configurations/components/RestaurantDetails.vue'
import RestaurantConfigurations from '@/app/features/restaurant-configurations/components/RestaurantConfigurations.vue'
import Loader from '@/app/components/Loader.vue'
import MainSection from '@/app/components/MainSection.vue'
import HeaderSection from '@/app/components/HeaderSection.vue'
import restaurantConfigurationsStore from '@/app/features/restaurant-configurations/restaurant-configuration-store'

export default defineComponent({
  name: 'Configurations',
  components: {
    RestaurantDetails,
    RestaurantConfigurations,
    Loader,
    MainSection,
    HeaderSection,
  },
  async setup() {
    const toast: any = inject('toast')
    const route = useRoute()
    const {
      loadRestaurantConfigurations,
      saveRestaurantConfigurations,
      restaurantId,
      restaurantDetails,
      localConfigurations,
      definitions,
      formState,
    } = restaurantConfigurationsStore()

    const restaurantIdParam = route.params.restaurantId.toString()
    loadRestaurantConfigurations(restaurantIdParam)

    const onConfigChanged = (id: string) => {
      localConfigurations.value.changeState(id)
      formState.value.hasChanged = true
    }

    function cancel() {
      localConfigurations.value.reset()
      formState.value.hasChanged = false
    }

    const submit = async () => {
      formState.value.isSubmitting = true
      await saveRestaurantConfigurations()
      toast.success('Configurations updated successfully!')
      formState.value.hasChanged = false
      formState.value.isSubmitting = false
    }

    return {
      onConfigChanged,
      formState,
      cancel,
      submit,
      restaurantDetails,
      restaurantId,
      definitions,
      localConfigurations,
    }
  },
})
