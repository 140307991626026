
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProgressBar',
  props: {
    value: Number,
  },
  setup() {
    function sanitise(progressValue: number) {
      if (progressValue > 100) {
        return 100
      }
      if (progressValue < 0) {
        return 0
      }
      return Math.round((progressValue + Number.EPSILON) * 100) / 100
    }

    return { sanitise }
  },
})
