import axios, { AxiosRequestConfig } from 'axios'
import appStore from '@/app/app-store'
import appConfig from './config'
import oktaAuth from './auth'

axios.defaults.baseURL = appConfig.orderpadApiHost

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const { tenant } = appStore()
  const token = oktaAuth.getAccessToken()

  config.headers['Content-Type'] = 'application/json'
  config.headers['Authorization'] = `Bearer ${token}`
  config.headers['X-JE-Tenant'] = tenant.value
  return config
})
