
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ResultsPanel',
  props: {
    results: {
      type: Object as PropType<Map<string, boolean>>,
    },
    elapsedTime: String,
  },
  setup() {
    return {}
  },
})
