<template>
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-lg-auto">
        <h1>Oops! Page not found.</h1>
        <RestaurantForm />
      </div>
    </div>
  </div>
</template>

<script>
import RestaurantForm from './components/RestaurantForm.vue'

export default {
  name: 'Error404',
  components: { RestaurantForm },
}
</script>
