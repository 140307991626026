<template>
  <div class="container bg-white">
    <div class="row pt-3">
      <div class="col-md-12 col-lg-4 logo">
        <img :src="require('../assets/logo-justeat.svg')" alt="Logo" />
      </div>
      <div class="col-lg-4">
        <div class="app-name">Orderpad Configuration</div>
      </div>
      <div class="col-md-12 col-lg-4">
        <AuthInfo></AuthInfo>
      </div>
    </div>
  </div>
  <hr class="header-hr" />
  <Suspense>
    <template #default>
      <router-view />
    </template>
    <template #fallback>
      <div>Loading...</div>
    </template>
  </Suspense>
</template>

<script type="ts">
import { defineComponent} from 'vue'
import appStore from '@/app/app-store'
import AuthInfo from '@/app/components/AuthInfo.vue'

export default defineComponent({
  name: 'app',
  components: {
    AuthInfo
  },
  setup() {
    const { loadTenant } = appStore()
    loadTenant()
  }
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.logo {
  text-align: left;
}

.user-auth {
  i {
    font-size: 14px;
    padding: 4px 5px;
  }

  .username {
    font-size: 12px;
  }
}

.app-name {
  font-size: 24px;
  font-weight: 700;
  color: #535353;
  font-family: 'Ubuntu', sans-serif;
  text-align: left;
}

.header-hr {
  border-bottom: 4px solid #fa0029;
  margin: 0;
  opacity: 1;
  margin-bottom: 10px;
}
</style>
