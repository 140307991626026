
import { defineComponent, inject, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'RestaurantForm',
  setup() {
    let restaurantId = ref('')
    const toast: any = inject('toast') // any because we don't have types for it
    const router = useRouter()

    function goto() {
      if (restaurantId.value === undefined || restaurantId.value === '') {
        toast.error('Invalid restaurantId')
        return
      }
      router.push({
        name: 'RestaurantConfigurations',
        params: {
          restaurantId: restaurantId.value,
        },
      })
    }
    return { restaurantId, goto }
  },
})
