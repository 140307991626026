import { computed, reactive } from 'vue'
import { LoadConfigurationDefinitions } from '@/app/api/restaurant-api'
import { ConfigurationDefinition } from '@/app/api/api-types'
import appConfig from '@/app/boot/config'

export interface AppState {
  tenant: string
  configurationDefinitions: ConfigurationDefinition[] | undefined
}

const state = reactive<AppState>({
  tenant: '',
  configurationDefinitions: undefined,
})

export default function appStore(): any {
  async function loadConfigurationDefinitions() {
    state.configurationDefinitions = await LoadConfigurationDefinitions()
  }

  function loadTenant() {
    const supportedTenants: string[] = appConfig.supportedTenants
    const defaultTenant: string = supportedTenants[0]
    const host = window.location.hostname
    state.tenant =
      supportedTenants.find((t) => hostIsOkForTenant(host, t)) || defaultTenant
  }

  function hostIsOkForTenant(host: string, tenant: string) {
    host = host.toLowerCase()
    tenant = tenant.toLowerCase()
    return host.endsWith(tenant) || host.startsWith(`${tenant}-`)
  }

  return {
    loadConfigurationDefinitions,
    loadTenant,
    definitions: computed(() => state.configurationDefinitions),
    tenant: computed(() => state.tenant),
  }
}
