import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "progress" }
const _hoisted_2 = ["aria-valuenow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "progress-bar progress-bar-striped bg-success",
      role: "progressbar",
      style: _normalizeStyle({ width: _ctx.sanitise(_ctx.value) + '%' }),
      "aria-valuenow": _ctx.value,
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }, _toDisplayString(_ctx.sanitise(_ctx.value)) + "% ", 13, _hoisted_2)
  ]))
}