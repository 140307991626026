import { ConfigurationDefinition } from '@/app/api/api-types'
import { ConfigurationItem } from './configuration-item'

export class LocalBulkUpdateConfigurations {
  public configs: ConfigurationItem[]

  get hasIncluded(): boolean {
    return this.configs.filter((x) => x.isIncluded).length > 0
  }

  constructor(configDefinitions: ConfigurationDefinition[]) {
    this.configs = this.mapToConfigItems(configDefinitions)
  }

  isIncluded(id: string): boolean {
    const item = this.configs.find((x) => x.id === id)
    if (item === undefined) {
      return false
    }
    return item.isIncluded
  }

  getState(id: string): boolean {
    const item = this.configs.find((x) => x.id === id)
    if (item === undefined) {
      return false
    }
    return item.state
  }

  changeInclusion(id: string): boolean {
    const item = this.configs.find((x) => x.id === id)
    if (item === undefined) {
      return false
    }
    item.isIncluded = !item.isIncluded
    return true
  }

  changeState(id: string): boolean {
    const item = this.configs.find((x) => x.id === id)
    if (item === undefined) {
      return false
    }
    item.state = !item.state
    return true
  }

  getIncluded(): Map<string, boolean> {
    return new Map(
      this.configs.filter((x) => x.isIncluded).map((x) => [x.id, x.state])
    )
  }

  reset(): boolean {
    this.configs.forEach((x) => {
      x.state = false
      x.isIncluded = false
    })
    return true
  }

  private mapToConfigItems(
    configDefinitions: ConfigurationDefinition[]
  ): ConfigurationItem[] {
    const configItems = new Array<ConfigurationItem>()
    for (let i = 0; i < configDefinitions.length; i++) {
      const definition = configDefinitions[i]
      configItems[i] = {
        id: definition.Id,
        name: definition.Name,
        state: false,
        isIncluded: false,
      }
    }
    return configItems
  }
}
