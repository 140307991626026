
import { defineComponent, PropType } from 'vue'
import { ConfigurationItem } from '@/app/features/tools/bulk-updater/configuration-item'

export default defineComponent({
  name: 'ConfigurationItems',
  emits: ['includedChanged', 'enabledChanged'],
  props: {
    items: {
      type: Object as PropType<ConfigurationItem[]>,
    },
  },
  setup(props, context) {
    function onIncludedChanged(id: string) {
      context.emit('includedChanged', id)
    }

    function onEnabledChanged(id: string) {
      context.emit('enabledChanged', id)
    }

    return {
      onIncludedChanged,
      onEnabledChanged,
    }
  },
})
