import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { LoginCallback, navigationGuard } from '@okta/okta-vue'

import Home from '@/app/features/home/Home.vue'
import RestaurantConfigurations from '@/app/features/restaurant-configurations/RestaurantConfigurations.vue'
import Error404 from '@/app/features/home/Error404.vue'
import BulkUpdater from '@/app/features/tools/bulk-updater/BulkUpdater.vue'
import Tools from '@/app/features/tools/Tools.vue'
import TestAccess from '@/app/features/test-access/TestAccess.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login-callback',
    component: LoginCallback,
  },
  {
    path: '/restaurants/:restaurantId',
    name: 'RestaurantConfigurations',
    component: RestaurantConfigurations,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tools',
    name: 'Tools',
    component: Tools,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/test-access',
    name: 'Test-Access',
    component: TestAccess,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tools/bulk-updater',
    name: 'BulkUpdater',
    component: BulkUpdater,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: Error404,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(navigationGuard)

export default router
