import { computed, reactive } from 'vue'
import {
  LoadConfigurationDefinitions,
  SaveMultipleRestaurantsConfigurations,
} from '@/app/api/restaurant-api'
import { LocalBulkUpdateConfigurations } from '@/app/features/tools/bulk-updater/local-bulk-update-configurations'
import { BulkUpdateConfigForm } from '@/app/features/tools/bulk-updater/bulk-update-form'
import { UpdateRestaurantsConfigurationsRequest } from '@/app/api/api-types'

export interface BulkUpdateState {
  localBulkUpdateConfigurations: LocalBulkUpdateConfigurations | undefined
  formState: BulkUpdateConfigForm
  restaurantIds: string[]
  updateResults: Map<string, boolean | undefined>
}

const state = reactive<BulkUpdateState>({
  localBulkUpdateConfigurations: undefined,
  formState: new BulkUpdateConfigForm(),
  restaurantIds: new Array<string>(),
  updateResults: new Map(),
})

export default function bulkUpdateStore(): any {
  async function initialise() {
    const configurationDefinitions = await LoadConfigurationDefinitions()
    state.localBulkUpdateConfigurations = new LocalBulkUpdateConfigurations(
      configurationDefinitions
    )
  }

  function setRestaurantIds(restaurantIds: string[]) {
    state.restaurantIds = restaurantIds
    resetResults()
  }

  function resetResults() {
    state.updateResults = new Map()
    if (state.restaurantIds.length === 0) return
    state.restaurantIds.forEach((id) => state.updateResults?.set(id, undefined))
  }

  async function updateRestaurantsConfigurations(restaurantIds: string[]) {
    if (!state.localBulkUpdateConfigurations) return
    let succeeded = true
    try {
      const includedConfigs = state.localBulkUpdateConfigurations.getIncluded()
      const payload: UpdateRestaurantsConfigurationsRequest = {
        Configurations: Object.fromEntries(includedConfigs),
        RestaurantIds: restaurantIds,
      }
      await SaveMultipleRestaurantsConfigurations(payload)
    } catch (e) {
      console.log(e)
      succeeded = false
    }
    restaurantIds.forEach((restaurantId) => {
      state.updateResults.set(restaurantId, succeeded)
    })
  }

  return {
    initialise,
    setRestaurantIds,
    updateRestaurantsConfigurations,
    resetResults,
    localBulkUpdateConfigurations: computed(
      () => state.localBulkUpdateConfigurations
    ),
    formState: computed(() => state.formState),
    restaurantIds: computed(() => state.restaurantIds),
    updateResults: computed(() => state.updateResults),
  }
}
