
import MainSection from '@/app/components/MainSection.vue'
import Header from '@/app/components/HeaderSection.vue'
export default {
  name: 'Tools',
  components: { MainSection, Header },
  setup() {
    return {}
  },
}
