
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'RestaurantIdsInput',
  emits: ['idsUpdated'],

  setup(props, context) {
    const input = ref('')
    const restCount = ref('0')
    function onChanged() {
      let rpIds = input.value.replace(/\s/g, '').split(',')
      rpIds = sanitise(rpIds)
      restCount.value = rpIds.length.toString()
      context.emit('idsUpdated', rpIds)
    }

    //remove empty and duplicated records
    function sanitise(rpIds: string[]): string[] {
      let sanitised = rpIds.filter((x) => x !== '')
      return [...new Set(sanitised)]
    }

    return {
      onChanged,
      input,
      restCount,
    }
  },
})
