<template>
  <div class="bg-light pt-4 pb-4">
    <div class="container bg-white mt-2 p-3">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MainSection',
}
</script>
