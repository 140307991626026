export class Timer {
  private startTime: number
  private endTime: number

  constructor() {
    this.startTime = 0
    this.endTime = 0
  }

  start(): void {
    this.startTime = performance.now()
  }

  end(): void {
    this.endTime = performance.now()
  }

  elapsedFormatted(): string {
    const timeDiff = Math.round((this.endTime - this.startTime) / 1000)
    return new Date(timeDiff * 1000).toISOString().substr(11, 8)
  }
}
