import { Timer } from '@/app/utils/timer'

export class BulkUpdateConfigForm {
  public isSubmitting: boolean
  public canSubmit: boolean
  public completedPercentage: number
  public cancelRequested: boolean
  private timer: Timer
  public lastElapsedTime: string

  constructor() {
    this.isSubmitting = false
    this.canSubmit = false
    this.cancelRequested = false
    this.completedPercentage = 0
    this.timer = new Timer()
    this.lastElapsedTime = ''
  }

  public start(): void {
    this.isSubmitting = true
    this.completedPercentage = 0
    this.timer.start()
    this.resetLastElapsedTime()
  }

  public end(): void {
    this.isSubmitting = false
    this.cancelRequested = false
    this.timer.end()
    this.lastElapsedTime = this.timer.elapsedFormatted()
  }

  public resetLastElapsedTime(): void {
    this.lastElapsedTime = ''
  }

}
