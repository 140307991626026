
import { computed, defineComponent, PropType } from 'vue'
import { ConfigurationDefinition } from '@/app/api/api-types'

export default defineComponent({
  name: 'RestaurantConfigurations',
  props: {
    definitions: {
      type: Object as PropType<ConfigurationDefinition[]>,
    },
    configurations: {
      type: Object as PropType<Map<string, boolean>>,
    },
  },
  emits: ['configChanged'],
  setup(props, context) {
    const groupedDefinitions = computed(
      () =>
        props.definitions?.reduce(
          (entryMap, e) =>
            entryMap.set(e.Category, [...(entryMap.get(e.Category) || []), e]),
          new Map()
        ) || []
    )

    function handleStateChanged(id: string) {
      context.emit('configChanged', id)
    }

    return { handleStateChanged, groupedDefinitions }
  },
})
