import { OktaAuth } from '@okta/okta-auth-js'
import appConfig from './config'

const redirectUrl = `${window.location.protocol}//\
${window.location.hostname}\
${window.location.port ? `:${window.location.port}` : ''}\
/login-callback`

const authConfig = {
  clientId: appConfig.oidc.clientId,
  redirectUri: redirectUrl,
  scopes: ['openid', 'email'],
  pkce: false,
  issuer: `${appConfig.oidc.authority}/oauth2/default`,
  metadata: {
    issuer: `${appConfig.oidc.authority}/oauth2/default`,
    authorization_endpoint: `${appConfig.oidc.authority}/oauth2/default/v1/authorize`,
    jwks_uri: `${appConfig.oidc.authority}/oauth2/default/v1/keys`,
  },
}
const oktaAuth = new OktaAuth(authConfig)
export default oktaAuth
