import { createApp } from 'vue'
import Toaster from '@meforma/vue-toaster'
import OktaVue from '@okta/okta-vue'
import App from '@/app/App.vue'
import router from '@/app/app-router'

import '@/app/boot'
import { oktaAuth } from '@/app/boot'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

const app = createApp(App).use(router).use(OktaVue, { oktaAuth }).use(Toaster, {
  position: 'top-right',
  duration: 2000,
})
app.provide('toast', app.config.globalProperties.$toast)
app.mount('#app')
