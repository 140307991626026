<template>
  <div class="text-center">
    <div class="spinner-border" role="status"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
}
</script>
