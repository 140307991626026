import { AppConfig } from './app-config'
const globalVariables = window as any

const appConfig: AppConfig = {
  oidc: {
    authority: globalVariables.OIDC_AUTHORITY,
    clientId: globalVariables.OIDC_CLIENT_ID,
  },
  orderpadApiHost: globalVariables.ORDERPAD_BASE_URL,
  supportedTenants: globalVariables.SUPPORTED_TENANTS,
}
export default appConfig
