export class LocalRestaurantConfigurations {
  public currentConfigs: Map<string, boolean>
  public serverConfigs: Map<string, boolean>
  public isReady: boolean

  constructor() {
    this.currentConfigs = new Map()
    this.serverConfigs = new Map()
    this.isReady = false
  }

  setConfiguration(configs: Map<string, boolean>): void {
    this.currentConfigs = new Map(configs)
    this.serverConfigs = new Map(configs)
    this.isReady = true
  }

  changeState(flagId: string): void {
    const currentState = this.currentConfigs.get(flagId)
    if (currentState !== undefined) {
      this.currentConfigs.set(flagId, !currentState)
    }
  }

  reset(): void {
    this.currentConfigs = new Map(this.serverConfigs)
  }

  clean(): void {
    this.currentConfigs = new Map()
    this.serverConfigs = new Map()
    this.isReady = false
  }
}
