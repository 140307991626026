import { computed, ComputedRef, reactive } from 'vue'
import { ConfigurationDefinition, Restaurant } from '@/app/api/api-types'
import {
  LoadRestaurantDetails,
  LoadRestaurantConfigurations,
  SaveRestaurantConfigurations,
  LoadConfigurationDefinitions,
} from '@/app/api/restaurant-api'
import { LocalRestaurantConfigurations } from '@/app/features/restaurant-configurations/local-restaurant-configurations'
import { RestaurantConfigurationsForm } from '@/app/features/restaurant-configurations/restaurant-configurations-form'

export interface RestaurantConfigurationState {
  restaurantId: string
  restaurantDetails: Restaurant | undefined
  restaurantConfigs: Map<string, boolean> | undefined
  localConfigurations: LocalRestaurantConfigurations
  configurationDefinitions: ConfigurationDefinition[]
  formState: RestaurantConfigurationsForm
}

export interface RestaurantConfigurationStore {
  loadRestaurantConfigurations: (restaurantId: string) => Promise<void>
  saveRestaurantConfigurations: () => Promise<void>
  restaurantId: ComputedRef<string>
  restaurantDetails: ComputedRef<Restaurant | undefined>
  localConfigurations: ComputedRef<LocalRestaurantConfigurations>
  definitions: ComputedRef<ConfigurationDefinition[] | undefined>
  formState: ComputedRef<RestaurantConfigurationsForm>
}

const state = reactive<RestaurantConfigurationState>({
  restaurantId: '',
  restaurantDetails: undefined,
  restaurantConfigs: undefined,
  localConfigurations: new LocalRestaurantConfigurations(),
  configurationDefinitions: [],
  formState: new RestaurantConfigurationsForm(),
})

export default function restaurantConfigurationsStore(): RestaurantConfigurationStore {
  async function loadRestaurantConfigurations(restaurantId: string) {
    state.localConfigurations.clean()
    state.restaurantId = restaurantId
    state.configurationDefinitions = await LoadConfigurationDefinitions()
    state.restaurantDetails = await LoadRestaurantDetails(state.restaurantId)
    const serverState = await LoadRestaurantConfigurations(restaurantId)
    state.localConfigurations.setConfiguration(serverState)
    state.formState = new RestaurantConfigurationsForm()
  }

  async function saveRestaurantConfigurations() {
    if (!state.localConfigurations) return
    const serverState = await SaveRestaurantConfigurations(
      state.restaurantId,
      state.localConfigurations.currentConfigs
    )
    state.localConfigurations.setConfiguration(serverState)
  }

  return {
    loadRestaurantConfigurations,
    saveRestaurantConfigurations,
    restaurantId: computed(() => state.restaurantId),
    restaurantDetails: computed(() => state.restaurantDetails),
    localConfigurations: computed(() => state.localConfigurations),
    definitions: computed(() => state.configurationDefinitions),
    formState: computed(() => state.formState),
  }
}
