
import { inject, defineComponent } from 'vue'
import MainSection from '@/app/components/MainSection.vue'
import HeaderSection from '@/app/components/HeaderSection.vue'
import ProgressBar from '@/app/components/ProgressBar.vue'
import Loader from '@/app/components/Loader.vue'
import RestaurantIdsInput from '@/app/features/tools/bulk-updater/components/RestaurantIdsInput.vue'
import ConfigurationItems from '@/app/features/tools/bulk-updater/components/ConfigurationItems.vue'
import bulkUpdateStore from '@/app/features/tools/bulk-updater/bulk-update-store'
import ResultsPanel from '@/app/features/tools/bulk-updater/components/ResultsPanel.vue'
import { chunkArray } from '@/app/utils/array-utils'

export default defineComponent({
  name: 'BulkUpdater',
  components: {
    HeaderSection,
    MainSection,
    ProgressBar,
    RestaurantIdsInput,
    ConfigurationItems,
    ResultsPanel,
    Loader,
  },
  async setup() {
    const toast: any = inject('toast')
    const {
      initialise,
      setRestaurantIds,
      resetResults,
      localBulkUpdateConfigurations,
      restaurantIds,
      formState,
      updateResults,
      updateRestaurantsConfigurations,
    } = bulkUpdateStore()

    initialise()
    const batchSize = 50

    const submit = async () => {
      const included = localBulkUpdateConfigurations.value.getIncluded()
      if (included === undefined || included.size < 1) {
        toast.warning('No configurations selected to be updated')
        return
      }

      formState.value.start()
      resetResults()

      const restaurantsCount = restaurantIds.value.length
      let completedCount = 0

      for (const chunk of chunkArray(restaurantIds.value, batchSize)) {
        if (formState.value.cancelRequested === true) {
          formState.value.completedPercentage = 100
          break
        }

        await updateRestaurantsConfigurations(chunk)
        completedCount += chunk.length
        formState.value.completedPercentage = calculateProgress(
          completedCount,
          restaurantsCount
        )
      }

      formState.value.end()
      console.log(formState.value.timer.elapsedFormatted())

      const successful = [...updateResults.value.values()].filter(
        (x) => x === true
      ).length

      toast.success(
        `${successful} out of ${restaurantsCount} RP's configurations updated successfully!`
      )
    }

    function calculateProgress(current: number, total: number): number {
      return (current / total) * 100
    }

    function cancel() {
      formState.value.cancelRequested = true
    }

    function reset() {
      localBulkUpdateConfigurations.value.reset()
      formState.value.canSubmit = false
    }

    function onIdsUpdated(rpIds: string[]) {
      setRestaurantIds(rpIds)
      formState.value.resetLastElapsedTime()
    }

    function onIncludedChanged(id: string) {
      if (localBulkUpdateConfigurations.value.changeInclusion(id)) {
        formState.value.canSubmit =
          localBulkUpdateConfigurations.value.hasIncluded
      }
    }

    function onEnabledChanged(id: string) {
      localBulkUpdateConfigurations.value.changeState(id)
    }

    return {
      onIdsUpdated,
      reset,
      onIncludedChanged,
      onEnabledChanged,
      submit,
      cancel,
      localBulkUpdateConfigurations,
      restaurantIds,
      updateResults,
      formState,
    }
  },
})
